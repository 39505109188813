import { Injectable } from '@angular/core';

import {
  ICondoFormInput,
  ICondoService,
} from '@core-services/condo';
import { Apollo } from 'apollo-angular';

import { firstValueFrom, map, Observable } from 'rxjs';
import {
  type IQueryGetCondos,
  type IMutationToggleAlphanumeriKeyboardUsageResponse,
  type IMutationToggleCommonAreaReservationTimeRange,
  type IMutationToggleCommonExpensesVisibilityResponseData,
  type IQueryGetCondoByID,
  type IMutationCondosRegister,
  type IMutationUpdateCondos,
  mutation_toggle_alphanumeri_keyboard_usage,
  mutation_toggle_common_area_reservation_time_range_support,
  TOGGLE_COMMON_EXPENSES_VISIBILITY,
  QUERY_GET_ALL_CONDOS,
  query_get_condo_by_id,
  mutation_register_condos,
  mutation_update_condos,
} from '@infra-adapters/graphql/Condos';
import {
  type ErrorMessage,
  type NumberOrNull,
  type StringOrNull,
  StorageKeys,
  BooleanOrNull,
} from '@core-interfaces/global';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import type { ICondoInput, ICondoUpdateInput } from '@core-ports/inputs/Condo';
import { ICondo } from '@core-ports/outputs/condo';
import { ErrorService } from './Error.service';
@Injectable({
  providedIn: 'root',
})
export class CondoManager implements ICondoService {
  errorMessages: ErrorMessage = {
    name: {
      required: 'Nombre es requerido.',
      minlength: 'Nombre debe contener al menos 4 caracteres',
      serverError: 'Nombre incorrecto',
    },
    pairing_code: {
      min: 'Código de pareo debe ser mayor a 0',
      minlength: 'Código de pareo debe contener al menos 4 caracteres',
      serverError: 'Código de pareo incorrecto',
    },
    maximum_parking_time: {
      required: 'Minutos de estacionamiento es requerido.',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Minutos de estacionamiento incorrecto.',
    },
    inactivity_time: {
      required: 'Tiempo de inactividad es requerido.',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Minutos de inactividad incorrecto.',
    },
    devices_number: {
      required: 'Cantidad de dispositivos es requerido',
      min: 'Código de pareo debe ser mayor a 0',
      serverError: 'Cantidad de dispositivos incorrecto.',
    },
  };

  condoForm: FormGroup<ICondoFormInput>;
  constructor(private apollo: Apollo, private errorService: ErrorService) {
    this.condoForm = new FormGroup<ICondoFormInput>({
      name: new FormControl<StringOrNull>(null, {
        validators: [Validators.required, Validators.minLength(4)],
      }),
      devices_number: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      inactivity_time: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      maximum_parking_time: new FormControl<NumberOrNull>(null, {
        validators: [Validators.required, Validators.min(1)],
      }),
      pairing_code: new FormControl<NumberOrNull>(null, {
        validators: [Validators.min(1), Validators.minLength(4)],
      }),
      primary_phone_number: new FormControl<StringOrNull>(null),
      secondary_phone_number: new FormControl<StringOrNull>(null),
      address: new FormControl<StringOrNull>(null),
    });
  }

  async condoLoadRequest(): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .query<IQueryGetCondos>({
        query: QUERY_GET_ALL_CONDOS,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.GetAllCondos) {
            return response.data.GetAllCondos;
          } else {
            throw new Error('No se pudo obtener listado de condominios');
          }
        })
      );
  }
  async condoToggleCommonAreaReservationTimeRange(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<IMutationToggleCommonAreaReservationTimeRange, { id: number }>({
        mutation: mutation_toggle_common_area_reservation_time_range_support,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (
            response.data &&
            response.data.ToggleCommonAreaReservationTimeRangeSupport
          ) {
            return response.data.ToggleCommonAreaReservationTimeRangeSupport;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }
  async condoToggleAlphanumericKeyboardUsage(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<IMutationToggleAlphanumeriKeyboardUsageResponse, { id: number }>({
        mutation: mutation_toggle_alphanumeri_keyboard_usage,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.toggleAlphanumeriKeyboardUsage) {
            return response.data.toggleAlphanumeriKeyboardUsage;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }
  async condoToggleCommonExpensesVisibility(
    id: number
  ): Promise<Observable<Array<ICondo>>> {
    return this.apollo
      .mutate<
        IMutationToggleCommonExpensesVisibilityResponseData,
        { id: number }
      >({
        mutation: TOGGLE_COMMON_EXPENSES_VISIBILITY,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.toggleCommonExpensesVisibility) {
            return response.data.toggleCommonExpensesVisibility;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async condoRegister(inputs: ICondoInput): Promise<Observable<Array<ICondo>>> {
    console.log('request para registro');

    return this.apollo
      .mutate<IMutationCondosRegister, { FormCreateCondo: ICondoInput }>({
        mutation: mutation_register_condos,
        variables: { FormCreateCondo: inputs },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.RegisterCondo) {
            return response.data.RegisterCondo;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async condoUpdate(
    inputs: ICondoUpdateInput
  ): Promise<Observable<Array<ICondo>>> {
    console.log('request para registro');

    return this.apollo
      .mutate<IMutationUpdateCondos, { FormUpdateCondo: ICondoUpdateInput }>({
        mutation: mutation_update_condos,
        variables: { FormUpdateCondo: inputs },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          console.log('response', response);

          if (response.data && response.data.UpdateCondo) {
            return response.data.UpdateCondo;
          } else {
            throw new Error('No se pudo actualizar condominio');
          }
        })
      );
  }

  async getCondoByID(id: number) {
    return this.apollo
      .query<IQueryGetCondoByID>({
        query: query_get_condo_by_id,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response) => {
          if (response.data && response.data.GetCondo) {
            return response.data.GetCondo;
          } else {
            throw new Error('No se pudo obtener listado de condominios');
          }
        })
      );
  }

  setGlobalCondo(id: number, name: string) {
    sessionStorage.setItem(StorageKeys.session_condo, `${id}`);
    sessionStorage.setItem(StorageKeys.session_condo_name, name);
  }

  removeGlobalCondo() {
    sessionStorage.removeItem(StorageKeys.session_condo);
    sessionStorage.removeItem(StorageKeys.session_condo_name);
  }

  getGlobalCondo() {
    return sessionStorage.getItem(StorageKeys.session_condo);
  }

  getGlobalCondoName() {
    return sessionStorage.getItem(StorageKeys.session_condo_name);
  }

  getErrorMessage(controlName: keyof ICondoFormInput): string | null {
    const inputControl = this.condoForm.get(controlName);
    if (inputControl && inputControl.errors) {
      if (inputControl.errors['serverError']) {
        return inputControl.errors['serverError'];
      }
      for (const errorKey in inputControl.errors) {
        if (errorKey in this.errorMessages[controlName]) {
          return this.errorMessages[controlName][errorKey];
        }
      }
    }
    return null;
  }
}
